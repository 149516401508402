import Image from "next/legacy/image"
import { ReactNode } from "react"

import Typography from "../Typography"

type AccordionProps = {
  children: ReactNode
  expanded?: boolean
  onClick?: () => void
  label: string
}

export const Accordion = (props: AccordionProps) => {
  const { children, expanded = false, onClick, label } = props
  const className = expanded ? "h-auto py-4" : "h-0"
  const imgClass = expanded ? "rotate-180" : ""

  return (
    <div className="px-6">
      <button
        type="button"
        onClick={onClick}
        className="min-h-[64px] flex items-center justify-between border-b-[0.5px] border-b-border-50 w-full"
        aria-expanded={expanded}
      >
        <Typography type="p_15" fontWeight="semibold">
          {label}
        </Typography>

        <div>
          <Image
            src="/assets/icons/up-accordion.svg"
            height={16}
            width={16}
            layout="fixed"
            alt="Up arrow"
            className={`${imgClass} transition-transform`}
          />
        </div>
      </button>
      <div className={`transition-all ${className}  w-full overflow-hidden`}>
        {expanded && children}
      </div>
    </div>
  )
}
