import { URLFactory } from "@/src/sb/business-layer/factories/URLFactory"
import { Report as ArticleV2 } from "@/src/sb/business-layer/types"
import Typography from "@/src/sb/components/Typography"
import { Article } from "@/src/sb/core"
import {
  getAuthor,
  getImageSrc,
  isReportFree
} from "@/src/sb/core/utils/helpers"
import Image from "next/legacy/image"
import React from "react"

import Badge from "../Badge"
import { ArticleCard } from "./ArticleCard"

export type TodaysArticleProps = {
  article: Article
  onClick?: () => void
}
export const TodaysArticle = (props: TodaysArticleProps) => {
  const { article, onClick } = props

  const image = getImageSrc(article)

  const author = getAuthor(article.author)
  const slug = article.slug

  const mainTopic = article.main_topic
  const articleLink = URLFactory.report(
    slug,
    isReportFree({ type: article.type } as ArticleV2)
  )
  return (
    <div>
      <Typography type="h4" fontWeight="regular">
        Latest analysis
      </Typography>

      <div className="mt-10  portrait_tablet:block  grid grid-cols-[minmax(500px,1fr)_minmax(auto,1fr)] gap-18 pr-16 portrait_tablet:pr-0">
        {image.src ? (
          <div className=" portrait_tablet:hidden block">
            <a
              href={articleLink}
              onClick={onClick}
              className="text-btn_15 h-full hover:underline text-stears_black"
            >
              <ArticleCard.Image
                src={image.src}
                srcSet={image.srcSet}
                width={638}
                height={402}
                style=""
                alt="today's article"
              />
            </a>
          </div>
        ) : null}

        {image.src ? (
          <div className="hidden portrait_tablet:block relative w-full h-[337px] mobile:h-[280px] portrait_tablet:h-[380px] max-w-[800px] ">
            <a
              href={articleLink}
              onClick={onClick}
              className="text-btn_15 h-full hover:underline text-stears_black"
            >
              <Image
                src={image.src}
                alt="today's article"
                layout="fill"
                objectFit="cover"
              />
            </a>
          </div>
        ) : null}

        <div className="flex items-center">
          <div>
            <div className="portrait_tablet:pt-4">
              <a
                href={articleLink}
                onClick={onClick}
                className="text-btn_15 h-full hover:underline text-stears_black"
              >
                <Typography type="h2" family="jakarta" fontWeight="semibold">
                  {article.title}
                </Typography>
              </a>

              <div className="mb-6 mt-4">
                <Typography type="p_18" family="jakarta" fontWeight={"light"}>
                  {article.summary}
                </Typography>
              </div>

              <div className="flex items-center">
                <div>
                  <Typography type="p_12" family={"sans"}>
                    {author.name ? author.name : ""}
                  </Typography>
                </div>
                {mainTopic && (
                  <div className="ml-6">
                    <Badge
                      label={mainTopic.name}
                      color="gray"
                      size="sm"
                      href={URLFactory.topicPage(mainTopic.slug)}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TodaysArticle
