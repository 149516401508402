import Link from "@/src/common/components/Link"
import Image from "next/legacy/image"
import React from "react"

const SOCIAL_MEDIA_PLATFORMS = {
  twitter: {
    name: "Twitter",
    icon: "/assets/icons/x.svg",
    link: "https://twitter.com/StearsInsights"
  },
  linkedin: {
    name: "LinkedIn",
    icon: "/assets/icons/linkedin.svg",
    link: "https://linkedin.com/company/stears"
  },
  instagram: {
    name: "Instagram",
    icon: "/assets/icons/ig.svg",
    link: "https://www.instagram.com/stearsco/"
  }
}

export type SocialIconProps = {
  platform: keyof typeof SOCIAL_MEDIA_PLATFORMS
}

const SocialIcon = (props: SocialIconProps) => {
  const { platform } = props
  const { icon, link, name } = SOCIAL_MEDIA_PLATFORMS[platform]

  return (
    <Link href={link} target={"_blank"}>
      <Image
        src={icon}
        width={24}
        height={24}
        layout="fixed"
        alt={`Stears Insight' ${name}`}
      />
    </Link>
  )
}

export default SocialIcon
