import Link from "@/src/common/components/Link"
import { ReactNode } from "react"

import { footer, pages } from "../../constants/copy"
import Logo from "../Logo"
import FooterSection from "./FooterSection"

const { BOTTOM_LINKS } = footer

type FooterProps = {
  onLinkClick?: (label: string) => void
  logoOverride?: ReactNode
  additionalSections?: ReactNode
  userType?: string
}

/**
 * Two problems here, identified while merging codebases and adopting the same footer for both SB and Elections:
 *
 * 1. The original Stears logo (white) has roughly an extra 20px on the left.
 * The above has been corrected by creating a negative margin. See <Logo className="ml-[-20px]" type="white" />}
 *
 * 2. The two codebases used different approaches for media queries. See "tailwind.config.js" for the "tablet|mobile" approach vs the "md,-md,md!" approach.
 * Prefer the latter approach ("md,-md,md!") and in time, we will remove the other approach. Or raise this with Bo if you think we should prefer the tablet|mobile approach.
 */
const Footer = (props: FooterProps) => {
  const { onLinkClick, additionalSections, logoOverride, userType } = props
  const userIsLoggedIn = userType !== "anonymous"

  return (
    <footer className="bg-stears_black text-stears_white font-jakarta pb-4">
      <div className="border-b border-b-gray-600 tablet:bg-border-50">
        <div className="max-w-screen-2xl mx-auto px-5 lg:px-0">
          <div className="pl-10 pb-7 tablet:pl-0 tablet:pb-4 pt-6 tablet:pt-4 portrait_tablet:py-4 portrait_tablet:pl-4">
            {logoOverride ? (
              logoOverride
            ) : userIsLoggedIn ? (
              <Link legacyBehavior href={pages.home}>
                <a>
                  <Logo className="ml-[-20px]" type="white" />
                </a>
              </Link>
            ) : (
              <Logo className="ml-[-20px]" type="white" />
            )}
          </div>
        </div>
      </div>

      <FooterSection onLinkClick={onLinkClick} />
      {additionalSections}

      <div className="px-6 border-y-[0.5px] border-border-50">
        <div className="mx-auto sm:w-80 py-5">
          <div className="flex justify-between text-gray-200 text-sm">
            {BOTTOM_LINKS.map((item, index) => (
              <div key={index}>
                <Link legacyBehavior href={item.href}>
                  <a
                    onClick={() => onLinkClick?.(item.linkText)}
                    className="hover:underline text-gray-400"
                  >
                    {item.linkText}
                  </a>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
