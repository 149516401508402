import { SyntheticEvent } from "react"

import { BADGE_COLORS } from "../Badge"
import { MainFeatureBlock } from "./MainFeatureBlock"
import { Landscape, RegularBlock } from "./RegularBlock"

export type ArticleBlockProps = {
  imageType: "rectangle" | "lg-square" | "md-square"
  fixed?: boolean
  image: { src: string; alt: string; srcSet?: string } | null
  topic?: { href: string; label: string; color: keyof typeof BADGE_COLORS }
  title: string
  slug: string
  onClick?: (event: SyntheticEvent) => void
  summary?: string
  authorName: string
  publishedDate?: Date
  type?: string
}

export type ArticleBlockTypeProps = {
  blockType: "mainFeature" | "regular"
}

export const ArticleBlock = ({
  blockType,
  ...props
}: ArticleBlockTypeProps & ArticleBlockProps) => (
  <>
    {blockType == "regular" ? (
      <RegularBlock {...props} />
    ) : (
      <MainFeatureBlock {...props} />
    )}
  </>
)

ArticleBlock.Landscape = Landscape

export default ArticleBlock
