import dateAndTime from "date-and-time"
import { ReactNode, SyntheticEvent } from "react"
import { Shimmer, Image as ShimmerImage } from "react-shimmer"

import Badge from "../Badge"
import { BADGE_COLORS } from "../Badge"

export const dateFormat = (date: Date): string | undefined => {
  return dateAndTime.format(date, "DD MMM YYYY")
}

type CardContainerProps = {
  children: ReactNode
  extraStyles: string
}
const CardContainer = ({ children, extraStyles }: CardContainerProps) => (
  <div className={`bg-transparent ${extraStyles}`}>{children}</div>
)

type CardImageProps = {
  src: string
  alt?: string
  style: string
  srcSet?: string
  height?: number
  width?: number
}
const CardImage = ({
  src,
  alt,
  style,
  srcSet,
  width = 1,
  height = 1
}: CardImageProps) => (
  <div className={`relative ${style}`}>
    <ShimmerImage
      src={src}
      fallback={<Shimmer width={width} height={height} />}
      NativeImgProps={{
        alt,
        srcSet,
        className: `${style} object-cover`,
        width,
        height
      }}
    />
  </div>
)

type CardTopicProps = {
  href: string
  label: string
  size: "sm" | "xs"
  color: keyof typeof BADGE_COLORS
}
const CardTopic = ({ href, label, size, color }: CardTopicProps) => (
  <Badge label={label} color={color} size={size} href={href} />
)

type CardTextProps = {
  children: string | undefined
  style: string
  href?: string
  onClick?: (event: SyntheticEvent<HTMLAnchorElement>) => void
}
const CardText = ({ children, style, href, onClick }: CardTextProps) => {
  const Text = <p className={style}>{children}</p>
  if (href) {
    return (
      <a
        href={href}
        onClick={onClick}
        className="tablet:hover:no-underline hover:underline"
      >
        {Text}
      </a>
    )
  }
  return Text
}

type CardSummaryProps = {
  style: string
  summary: string
}
const CardSummary = ({ style, summary }: CardSummaryProps) => (
  <p className={style} dangerouslySetInnerHTML={{ __html: summary }} />
)

type FooterProps = {
  children: ReactNode
  style: string
}
const CardFooter = ({ children, style }: FooterProps) => (
  <div className={style}>{children}</div>
)

type CardContentProps = {
  children: ReactNode
  style: string
}
const CardBody = ({ children, style }: CardContentProps) => {
  return <div className={style}>{children}</div>
}

export const ArticleCard = {
  Container: CardContainer,
  Image: CardImage,
  Body: CardBody,
  Topic: CardTopic,
  Text: CardText,
  Summary: CardSummary,
  Footer: CardFooter
}
