import { footer } from "../../constants/copy"
import SocialIcon from "../SocialIcon"

const { STEARS_MISSION, COPYRIGHT } = footer

export const AboutStears = () => (
  <div className="px-10 flex-1 pt-10 tablet:pt-4 tablet:px-6 tablet:mt-5">
    <h6 className="tablet:mb-5 text-btn_15">About Stears</h6>
    <p className="text-p_15 mt-7 text-gray-200">{STEARS_MISSION}</p>
    <p className="text-p_15 mt-5 text-gray-400">{COPYRIGHT}</p>

    <div className="icons-container mt-12 text-stears_white flex gap-8 items-center mb-18 tablet:mb-7">
      <SocialIcon platform="instagram" />
      <SocialIcon platform="twitter" />
      <SocialIcon platform="linkedin" />
    </div>
  </div>
)
