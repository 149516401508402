import { URLFactory } from "@/src/sb/business-layer/factories/URLFactory"
import { ReportSummary } from "@/src/sb/business-layer/types"
import { isReportFree } from "@/src/sb/core/utils/helpers"

import { ArticleBlockProps } from "."
import { ArticleCard, dateFormat } from "./ArticleCard"

const landscapeImageTypes = {
  rectangle: "w-30 h-24",
  "lg-square": "w-30 h-20",
  "md-square": "w-30 h-20"
}

const portraitImageTypes = {
  rectangle: "h-46 w-full",
  "lg-square": "h-68 w-full",
  "md-square": "h-50 w-full"
}

const cardFonts = {
  title:
    "font-jakarta font-semibold text-article_card_title leading-6 tracking-tight",
  subtitle: ""
}

type LandscapeProps = Omit<ArticleBlockProps, "summary" | "published_date">

export const Landscape = ({
  imageType,
  image,
  title,
  slug,
  authorName,
  onClick,
  publishedDate,
  legacyStyles = true,
  ...rest
}: LandscapeProps & { legacyStyles?: boolean }) => (
  <ArticleCard.Container
    extraStyles={
      legacyStyles
        ? "flex-row py-4 justify-between hidden mobile:flex portrait_tablet:hidden tablet:flex items-center bg-blue-300"
        : "flex flex-row py-4 justify-between items-center bg-blue-300"
    }
  >
    <ArticleCard.Body style="w-2/3 py-0 mr-2">
      <ArticleCard.Text
        style="font-jakarta text-p_14 tracking-tight text-ellipsis font-semibold"
        href={URLFactory.report(
          slug,
          isReportFree({ type: rest.type } as ReportSummary)
        )}
        onClick={onClick}
      >
        {title}
      </ArticleCard.Text>

      <ArticleCard.Footer style="mt-2 text-p4 leading-[1.125rem] font-normal flex items-center">
        <ArticleCard.Text style="text-p_12 pr-2 min-w-[40px]">
          {authorName}
        </ArticleCard.Text>
        {publishedDate && (
          <ArticleCard.Text style="border-l  border-stears_black text-gray-600  min-w-[80px] text-p_12 pl-2">
            {dateFormat(publishedDate)}
          </ArticleCard.Text>
        )}
      </ArticleCard.Footer>
    </ArticleCard.Body>

    {image?.src ? (
      <a
        href={URLFactory.report(
          slug,
          isReportFree({ type: rest.type } as ReportSummary)
        )}
        onClick={onClick}
        className={`flex items-center ${landscapeImageTypes[imageType]}`}
      >
        <ArticleCard.Image
          src={image.src}
          alt={image.alt}
          srcSet={image.srcSet}
          width={111}
          height={70}
          style="h-[70px]"
        />
      </a>
    ) : null}
  </ArticleCard.Container>
)

export const Portrait = ({
  imageType,
  fixed,
  image,
  publishedDate,
  title,
  slug,
  summary,
  authorName,
  onClick,
  ...rest
}: ArticleBlockProps) => {
  const responsiveContainerStyle =
    "mobile:hidden portrait_tablet:flex tablet:hidden"
  const defaultContainerStyle = "flex flex-col w-full"
  const containerStyle = fixed
    ? defaultContainerStyle
    : `${defaultContainerStyle}${" "}${responsiveContainerStyle}`

  let shortSummary = summary?.trim() || ""
  shortSummary =
    shortSummary.length > 97 ? shortSummary.slice(0, 94) + "..." : shortSummary
  return (
    <ArticleCard.Container extraStyles={containerStyle}>
      {image?.src ? (
        <a
          onClick={onClick}
          href={URLFactory.report(
            slug,
            isReportFree({ type: rest.type } as ReportSummary)
          )}
        >
          <ArticleCard.Image
            src={image.src}
            alt={image.alt}
            srcSet={image.srcSet}
            height={184}
            width={315}
            style={portraitImageTypes[imageType]}
          />
        </a>
      ) : null}

      <ArticleCard.Body style="mt-3">
        <ArticleCard.Text
          style={`${cardFonts.title}  mt-3`}
          href={URLFactory.report(
            slug,
            isReportFree({ type: rest.type } as ReportSummary)
          )}
          onClick={onClick}
        >
          {title}
        </ArticleCard.Text>
        {shortSummary && (
          <ArticleCard.Summary
            style="font-jakarta font-light text-article_card_subtitle text-gray-800 mt-3 leading-5 text-gray-600 tracking-tight text-ellipsis h-10 overflow-hidden"
            summary={shortSummary}
          />
        )}

        <ArticleCard.Footer style="flex items-center mt-3 font-normal text-base divide-gray-600">
          <ArticleCard.Text style="text-gray-600 text-p_12 mr-3 text-accent-600">
            {authorName}
          </ArticleCard.Text>

          {publishedDate && (
            <ArticleCard.Text style="border-l text-gray-600 text-btn_12 pl-2">
              {dateFormat(publishedDate)}
            </ArticleCard.Text>
          )}
        </ArticleCard.Footer>
      </ArticleCard.Body>
    </ArticleCard.Container>
  )
}

export const RegularBlock = (props: ArticleBlockProps) => {
  const { fixed } = props
  return (
    <>
      {fixed ? (
        <Portrait {...props} />
      ) : (
        <>
          <Portrait {...props} />
          <Landscape {...props} />
        </>
      )}
    </>
  )
}
