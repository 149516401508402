import Link from "@/src/common/components/Link"

export const BADGE_COLORS = {
  red: "bg-red-50 text-red-800",
  blue: "bg-accent-50 text-gray-800",
  green: "bg-green-50 text-green-800",
  gray: "bg-gray-100 text-stears_black",
  yellow: "bg-yellow-50 text-yellow-800"
}

const BADGE_FONT_SIZES = {
  xs: "text-xs",
  sm: "text-sm"
}

export type BadgeProps = {
  label: string
  href?: string
  color: keyof typeof BADGE_COLORS
  size: keyof typeof BADGE_FONT_SIZES
}

const Badge = ({ label, color, size, href }: BadgeProps) => {
  const colorClasses = BADGE_COLORS[color]
  const fontSizeClass = BADGE_FONT_SIZES[size]
  const className = `${colorClasses} ${fontSizeClass} font-sans text-center py-1 px-2.5 inline-block rounded-full`

  return href ? (
    <Link legacyBehavior href={href} className={"font-jakarta"}>
      <a className={className}>{label}</a>
    </Link>
  ) : (
    <div className={className}>{label}</div>
  )
}

export default Badge
