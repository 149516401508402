import { URLFactory } from "@/src/sb/business-layer/factories/URLFactory"
import { ReportSummary } from "@/src/sb/business-layer/types"
import { isReportFree } from "@/src/sb/core/utils/helpers"

import { ArticleBlockProps } from "."
import { ArticleCard, dateFormat } from "./ArticleCard"

const mainFeatureStyles = {
  landscape: {
    container:
      "flex-row flex mobile:hidden portrait_tablet:hidden tablet:hidden",
    image: "h-110 w-160",
    body: "mt-14 pl-14",
    title:
      "font-jakarta font-bold mt-8 w-140 text-h2 leading-11 tracking-tighter",
    summary:
      "font-jakarta font-light text-gray-800 mt-6 w-150 text-xl tracking-tight",
    footer:
      "flex items-center mt-9 font-normal text-base divide-x divide-gray-600",
    authorName: "pr-4 text-blue-600",
    publishedDate: "pl-4 text-gray-600"
  },
  portrait: {
    container: "flex-col hidden mobile:flex portrait_tablet:flex tablet:flex",
    image: "h-46 portrait_tablet:h-90 w-full",
    body: "mt-2.5",
    title: "font-jakarta font-bold mt-3 text-xl tracking-tight",
    summary:
      "font-jakarta font-light text-gray-800 mt-3 text-base tracking-tight",
    footer:
      "flex items-center mt-3 font-normal text-sm divide-x divide-gray-600",
    authorName: "pr-4 text-blue-600",
    publishedDate: "pl-4 text-gray-600"
  }
}

const Portrait = ({
  image,
  topic,
  title,
  slug,
  summary,
  authorName,
  publishedDate,
  ...rest
}: ArticleBlockProps) => {
  const styles = mainFeatureStyles.portrait
  return (
    <ArticleCard.Container extraStyles={styles.container}>
      {image?.src ? (
        <ArticleCard.Image
          src={image.src}
          alt={image.alt}
          style={styles.image}
          srcSet={image.srcSet}
        />
      ) : null}
      <ArticleCard.Body style={styles.body}>
        {topic && (
          <ArticleCard.Topic
            href={topic.href}
            label={topic.label}
            color={topic.color}
            size="sm"
          />
        )}
        <ArticleCard.Text
          style={styles.title}
          href={URLFactory.report(
            slug,
            isReportFree({ type: rest.type } as ReportSummary)
          )}
        >
          {title}
        </ArticleCard.Text>
        {summary && (
          <ArticleCard.Summary style={styles.summary} summary={summary} />
        )}
        <ArticleCard.Footer style={styles.footer}>
          <ArticleCard.Text style={styles.authorName}>
            {authorName}
          </ArticleCard.Text>
          {publishedDate && (
            <ArticleCard.Text style={styles.publishedDate}>
              {dateFormat(publishedDate)}
            </ArticleCard.Text>
          )}
        </ArticleCard.Footer>
      </ArticleCard.Body>
    </ArticleCard.Container>
  )
}

const Landscape = ({
  image,
  topic,
  title,
  slug,
  summary,
  authorName,
  publishedDate,
  ...rest
}: ArticleBlockProps) => {
  const styles = mainFeatureStyles.landscape
  return (
    <ArticleCard.Container extraStyles={styles.container}>
      {image?.src ? (
        <ArticleCard.Image
          src={image.src}
          alt={image.alt}
          style={styles.image}
          srcSet={image.srcSet}
        />
      ) : null}
      <ArticleCard.Body style={styles.body}>
        {topic && (
          <ArticleCard.Topic
            href={topic.href}
            label={topic.label}
            color={topic.color}
            size="sm"
          />
        )}
        <ArticleCard.Text
          style={styles.title}
          href={URLFactory.report(
            slug,
            isReportFree({ type: rest.type } as ReportSummary)
          )}
        >
          {title}
        </ArticleCard.Text>
        {summary && (
          <ArticleCard.Summary style={styles.summary} summary={summary} />
        )}
        <ArticleCard.Footer style={styles.footer}>
          <ArticleCard.Text style={styles.authorName}>
            {authorName}
          </ArticleCard.Text>
          {publishedDate && (
            <ArticleCard.Text style={styles.publishedDate}>
              {dateFormat(publishedDate)}
            </ArticleCard.Text>
          )}
        </ArticleCard.Footer>
      </ArticleCard.Body>
    </ArticleCard.Container>
  )
}

export const MainFeatureBlock = (props: ArticleBlockProps) => (
  <>
    <Portrait {...props} />
    <Landscape {...props} />
  </>
)
