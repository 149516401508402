import Link from "@/src/common/components/Link"
import React, { useState } from "react"

import { footer } from "../../constants/copy"
import { Accordion } from "../Accordion"
import { AboutStears } from "./AboutStears"

const { QUICK_LINKS } = footer

type FooterItemsProps = {
  links: { linkText: string; href: string }[]
  onLinkClick?: (label: string) => void
}

type FooterLinkGroupProps = {
  title: string
  className?: string
  links: FooterItemsProps["links"]
  onLinkClick?: (label: string) => void
}

const FooterItems = (props: FooterItemsProps) => {
  const { links, onLinkClick } = props
  return (
    <ul>
      {links.map((item) => (
        <li key={item.linkText} className="mb-2.5">
          <Link legacyBehavior href={item.href}>
            <a
              onClick={() => onLinkClick?.(item.linkText)}
              className=" text-p_15 text-gray-400 hover:underline"
            >
              {item.linkText}
            </a>
          </Link>
        </li>
      ))}
    </ul>
  )
}

export const FooterLinkGroup = (props: FooterLinkGroupProps) => {
  const { title, links, className = "", onLinkClick } = props
  return (
    <div className={`company pr-24 last-of-type:pr-12 type ${className} mb-16`}>
      <h6 className="mb-5 text-btn_15 font-semibold">{title}</h6>

      <FooterItems links={links} onLinkClick={onLinkClick} />
    </div>
  )
}

type FooterSection = {
  onLinkClick?: (label: string) => void
}

const FooterSection = (props: FooterSection) => {
  const { onLinkClick } = props
  const [accordions, setAccordion] = useState({
    company: false,
    legal: false,
    products: false
  })

  const handleClick = (name: keyof typeof accordions) => () => {
    setAccordion({ ...accordions, [name]: !accordions[name] })
  }
  return (
    <div className="max-w-screen-2xl mx-auto">
      <div className="hidden tablet:block">
        <Accordion
          label="Company"
          onClick={handleClick("company")}
          expanded={accordions.company}
        >
          <FooterItems links={QUICK_LINKS.company} onLinkClick={onLinkClick} />
        </Accordion>
        <Accordion
          label="Legal"
          onClick={handleClick("legal")}
          expanded={accordions.legal}
        >
          <FooterItems links={QUICK_LINKS.legal} onLinkClick={onLinkClick} />
        </Accordion>
        <Accordion
          label="Products"
          onClick={handleClick("products")}
          expanded={accordions.products}
        >
          <FooterItems links={QUICK_LINKS.products} onLinkClick={onLinkClick} />
        </Accordion>
        <AboutStears />
      </div>
      <div className="tablet:hidden flex w-full">
        <div className="px-10 flex-1 pt-10 border-r border-r-gray-600 tablet:border-none tablet:pt-4 portrait_tablet:pt-12">
          <div className="flex justify-start flex-wrap tablet:pl-4">
            <FooterLinkGroup
              title="Company"
              links={QUICK_LINKS.company}
              onLinkClick={onLinkClick}
            />
            <FooterLinkGroup
              title="Legal"
              links={QUICK_LINKS.legal}
              onLinkClick={onLinkClick}
            />
            <FooterLinkGroup
              title="Products"
              links={QUICK_LINKS.products}
              onLinkClick={onLinkClick}
            />
          </div>
        </div>
        <AboutStears />
      </div>
    </div>
  )
}

export default FooterSection
